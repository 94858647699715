<template>
  <div
    v-show="hasReactions"
    ref="popoverWrapper"
    >
    <div
      v-show="isPopoverVisible"
      class="absolute  top-[-4rem] sm:top-[-2rem] select-none transform translate-x-[-4.5rem] bg-white border border-gray-300 rounded-2xl shadow-lg p-3 flex space-x-3 z-[100]"
    >
      <button
        v-for="reaction in allowedreactions"
        :key="reaction"
        class="text-xl transform transition duration-150 rounded-full"
        @click="sendReaction(reaction)"
      >
        {{ reaction }}
      </button>
     
  </div>
    <button
      @click="togglePopover"
      class="   h-10 w-10 flex items-center justify-center rounded-full text-center text-white"
      >
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-smile"><circle cx="12" cy="12" r="10"/><path d="M8 14s1.5 2 4 2 4-2 4-2"/><line x1="9" x2="9.01" y1="9" y2="9"/><line x1="15" x2="15.01" y1="9" y2="9"/></svg>
    </button>
  </div>
</template>
<script setup lang="ts">

const config = useRuntimeConfig()
const props = defineProps(['mobile'])
const { audioSource, isLive } = usePlayerState();

const isPopoverVisible = ref(false)
const popoverWrapper = ref<HTMLElement>()

let ws: WebSocket | null = null;

const wsSendReaction = (emoji: string) => {

}

watch(audioSource, 
  () => {
    
    const room = btoa(audioSource.value);

    if (ws instanceof WebSocket) ws.close();
    
    ws = new WebSocket(`${config.public.wsReactionUrl}/?room=${room}`);

    console.log(`${config.public.wsReactionUrl}/?room=${room}`);

    ws.addEventListener('message', (event) => {
      const payload = JSON.parse(event.data)
      if (payload.reaction) 
        displayReaction(payload.reaction)
      if (payload.allowedEmojis)
          allowedreactions.value = payload.allowedEmojis
    })

    ws.addEventListener('open', () => {
      console.log('ws open on room:', atob(room))
    })

  },
  { immediate: true }
)

const togglePopover = () => {
      isPopoverVisible.value = !isPopoverVisible.value;
};

let allowedreactions: Ref<string[]> = ref([])

const hasReactions = computed(() => allowedreactions.value.length > 0 && isLive.value)

function displayReaction(emoji: string) {
  // Crée un élément emoji pour l'animation
  const emojiElement = document.createElement("span");
  emojiElement.classList.add("emoji");
  emojiElement.textContent = emoji;

  // Position aléatoire horizontale et rotation initiale
  const xPos = Math.random() * 10 + 10;
  const rotation = Math.random() * 30 - 15; // Rotation entre -15 et 15 degrés
  emojiElement.style.left = `${xPos}%`;
  emojiElement.style.transform += ` rotate(${rotation}deg)`;

  // Ajoute l'élément au DOM pour l'animation
  document.body.appendChild(emojiElement);

  // Supprime l'élément après l'animation
  emojiElement.addEventListener("animationend", () => {
    emojiElement.remove();
  });
}

function sendReaction(emoji: string) {
  if (ws instanceof WebSocket && ws.readyState === WebSocket.OPEN)
    ws.send(JSON.stringify({ reaction: emoji }));
  displayReaction(emoji);
}

function clickOutsideHandler(event: MouseEvent) {
  if (popoverWrapper.value && !popoverWrapper.value.contains(event.target as Node))
    isPopoverVisible.value = false;
}

onMounted(() => {
  document.addEventListener('click', clickOutsideHandler);
})

onUnmounted(() => {
  if (ws instanceof WebSocket && ws.readyState === WebSocket.OPEN) ws.close()
  document.removeEventListener('click', clickOutsideHandler);
})

</script>

<style>
floating-reactions {
  display: flex;
  gap: 10px;
  font-size: 24px;
  position: relative;
  cursor: pointer;
}

.emoji {
  position: fixed;
  z-index: 999;
  font-size: 24px;
  opacity: 1;
  bottom: 3rem;
  transform-origin: center;
  transform: translate(-50%, 0) scale(1) rotate(0deg);
  animation: floatUp 2s ease-out forwards;
}

/* Keyframes avec rotation */
@keyframes floatUp {
  0% {
    opacity: 1;
    transform: translate(-50%, 0) scale(1) rotate(0deg);
  }
  50% {
    opacity: 0.8;
    transform: translate(-50%, -50px) scale(1.5) rotate(15deg);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -100px) scale(2) rotate(-15deg);
  }
}
</style>