<template>
  <div class="fixed bottom-0 left-0 z-40 h-[100px] w-full">
    <div
      class="flex w-full items-center justify-around gap-4 bg-neutral-800 px-4 py-3">
      <!-- cover ant title info -->
      <div class="flex w-1/5 max-w-xs items-center justify-start gap-4">
        <div class="relative flex w-full items-center gap-4">
          <PlayerCover
            class="shrink-0"
            :cover-width="60"
            cover-sizes="sm:100px" />

          <div class="relative w-[calc(100%-60px-1rem)] font-bold">
            <div
              v-if="content"
              class="inline-flex w-full items-center gap-2">
              <PlayerLiveDot />
              <ClientOnly>
                <HST
                  :text="content"
                  class="block text-xs font-bold text-neutral-700" />
              </ClientOnly>
            </div>
            <PlayerMainTitle />
            <PlayerSecondTitle
              class="w-full text-xs font-bold text-neutral-700" />
          </div>
        </div>
        <ClientOnly v-if="!isMobile">
          <PlayerEmote />
        </ClientOnly>
        <VolumeControl />
      </div>

      <!-- controls -->
      <div class="flex w-2/5 flex-col items-center gap-2">
        <PlayerControls text-size="text-xs" />

        <PlayerTimeline class="w-full xl:max-w-[600px] dt:max-w-[400px]" />
      </div>
      <div />

      <!-- radio dropdown -->
      <div class="flex w-1/5 justify-end">
        <RadioFilter
          v-if="showRadioList"
          @select="selectRadio" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const { t } = useI18n()
const { isMobile } = useScreen()

const { radio, showRadioList, subtitle, title, radioLabel } = usePlayerState()

const content = ref<null | string>(null)
watchEffect(() => {
  content.value = `${t('Player.listening')} ${radioLabel.value}`
})

const selectRadio = (r: string) => {
  radio.value = r.key as string
}
</script>
